// src/views/QuizView.js

import React from 'react';
import { useParams } from 'react-router-dom';
import Quiz from '../components/Quiz/Quiz';

const QuizView = ({ learnMode = false }) => {
    const { listId } = useParams();
    return <Quiz listId={listId} learnMode={learnMode} />;
};

export default QuizView;
