// src/views/LearnQuizView.js

import React from 'react';
import { useParams } from 'react-router-dom';
import LearnQuiz from '../components/LearnQuiz/LearnQuiz';

const LearnQuizView = () => {
    const { listId } = useParams();
    return <LearnQuiz listId={listId} />;
};

export default LearnQuizView;
