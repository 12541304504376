// src/views/AllQuestionsView.js

import React from 'react';
import AllQuestions from '../components/AllQuestions/AllQuestions';

const AllQuestionsView = () => (
    <div>
        <AllQuestions />
    </div>
);

export default AllQuestionsView;
